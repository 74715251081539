.shop {
	display: flex;
}

.shop .menu {
	min-width: 290px;
}

.menu {
	background: rgba(0, 0, 0, 0.2);
	padding: 5px;
	border-radius: 5px;
	height: 1%;
}

.menu ul {
	list-style-type: none;
	font-weight: bold;
}

.menu button {
	width: 100%;
	background: transparent;
	color: inherit;
	text-align: left;
	padding: 15px 20px;
}

.menu button.active {
	background: #ebebeb;
	color: #0a2026;
	border-radius: 3px;
}

.shop .tiles {
	flex-grow: 1;
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	margin-left: 20px;
}