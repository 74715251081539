div.gift-checkbox {
    margin-bottom: 8px;
}

div.gift-checkbox input {
    display: inline-block !important;
    width: unset;
    margin: 0 6px 0 0 !important;
}

div.gift-checkbox label {
    display: inline;
    color: #b7b7b7;
}

div.gift-checkbox a {
    color: #ffee93;
    text-decoration: underline;
}
