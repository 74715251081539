.game-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	background: rgba(0, 0, 0, 0.2);
	padding: 15px;
}

.game-list .game {
	display: block;
	width: 220px;
	height: 220px;
	margin: 15px;
}

.game-list .game img {
	width: 100%;
	height: 100%;
}