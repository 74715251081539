.game-page {
	display: flex;
	position: relative;
}

.game-page h1 {
	font-weight: normal;
	margin-bottom: 10px;
}

.game-page .info {
	flex-grow: 1;
}

.game-page .info-text {
	background: rgba(0, 0, 0, 0.2);
	padding: 15px;
}

.game-page .info-text a {
	text-decoration: underline;
}

.tabs {
	display: flex;
}

.tabs button, .tabs a {
	display: block;
	color: white;
	margin-right: 6px;
	padding: 10px 30px;
	background: rgba(255, 255, 255, 0.2);
	text-decoration: none;
}

.tabs button.selected {
	background: rgba(0, 0, 0, 0.2);
}

.game-page .top {
	margin-left: 15px;
	background: rgba(0, 0, 0, 0.2);
}

.game-page .top .header {
	text-align: center;
	font-weight: bold;
	color: #000;
	background-color: #ffee93;
	padding: 10px;
	font-size: 110%;
}

a.back-to-games {
	display: block;
	width: 40px;
	height: 40px;
	background-image: url('/back.png');
	background-position: center;
	background-size: 20px;
	background-repeat: no-repeat;
	position: absolute;
	top: 0px;
	left: -45px;
}