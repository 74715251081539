.top-menu {
	list-style-type: none;
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
	font-weight: bold;
	font-size: 110%;
}

.top-menu li {
	display: block;
	background-repeat: no-repeat;
	background-position: left center;
}

.top-menu li a.active {
	color: #ffee93;
}