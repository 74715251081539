.mobile-purchase .header {
	display: flex;
}

.mobile-purchase .header h1 {
	flex-grow: 1;
	font-size: 140%;
	text-align: center;
}

.mobile-purchase button.cart {
	background: transparent;
	color: #bdc1c6;
	min-width: 40px;
	padding: 0;
}

.mobile-purchase button img {
	display: inline-block;
	vertical-align: text-bottom;
	margin: 0 0;
}

.mobile-purchase input {
	box-sizing: border-box;
	width: 100%;
	margin-bottom: 10px;
}

.mobile-purchase .settings {
	display: flex;
}

.mobile-purchase .settings button {
	flex-grow: 1;
	background-color: #0a2026;
	color: #c4c7cc;
	border: 1px solid rgba(0, 0, 0, 0.3);
}

.mobile-purchase .settings button.active {
	background-color: #ffee93;
	color: #000;
	border: 1px solid #ffee93;
}

.mobile-purchase .buy {
	width: 100%;
}

.mobile-purchase .desc {
	background-color: #0a2026;
	border: 1px solid rgba(0, 0, 0, 0.3);
	padding: 20px;
}