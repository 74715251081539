body {
	background-color: #002e3c;
	background-image: url('/body.jpg');
	background-repeat: no-repeat;
	background-position: center top;
	color: #fff;
	font-family: 'Exo 2', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 12pt;
	overflow-y: scroll;
	text-shadow: rgba(0, 0, 0, 0.6) 1px 1px 2px;
}

a {
	color: #fff;
	text-decoration: none;
}

p:empty {
	height: 1em;
}

input, button {
	font-family: inherit;
	font-size: inherit;
	font-weight: bold;
	border: 0;
	padding: 10px;
}

input {
	background-color: #dcdcdc;
	color: #000;
}

button {
	background-color: #ffee93;
	color: black;
	cursor: pointer;
}

button:disabled {
	background-color: #afafaf;
}

hr {
	border: 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.3);
	margin: 15px 0;
}