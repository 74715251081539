.player {
	padding: 0px 0px;
}

.player-body {
	display: flex;
}

.player-left {
	flex-grow: 1;
	padding-right: 60px;
}

.player-left h1 {
	font-weight: bold;
}

.player-left p {
	margin-top: 30px;
}

.player-left h2 {
	font-weight: bold;
	margin-top: 30px;
	font-size: 110%;
}

.player-volume {
	margin-top: 15px;
	display: block;
	box-sizing: border-box;
	padding: 0px;
	width: 100%;
	appearance: none;
	width: 100%;
	height: 10px;
	background: #00505f;
	outline: none;
	border-radius: 5px;
}

.player-volume::-webkit-slider-thumb {
	appearance: none;
	width: 15px;
	height: 15px;
	background: #fff;
	cursor: pointer;
	border-radius: 100%;
}

.player-volume::-moz-range-thumb {
	width: 15px;
	height: 15px;
	background: #fff;
	cursor: pointer;
	border-radius: 100%;
}

.player-block-image img {
	width: 150px;
	height: 150px;
}

div.interact-button {
	display: flex;
	padding: 40px 0px;
	justify-content: center;
	justify-items: center;
}

button.interact-button {

}